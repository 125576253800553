import React, { Suspense, lazy } from "react";
import NavBar from "./components/Navbar";
const Hero = lazy(() => import("./components/Hero"));
const Experience = lazy(() => import("./components/Experience"));
const Contact = lazy(() => import("./components/Contact"));
const Skills = lazy(() => import("./components/Skills"));
const Footer = lazy(() => import("./components/Footer"));
const Resume = lazy(() => import("./components/Resume"));

function LoadingIcon() {
  return (
    <div className="flex items-center justify-center">
      <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500"></div>
    </div>
  );
}

function App() {
  return (
    <div className="">
      <NavBar />
      <Suspense fallback={<div className="min-h-screen flex justify-center text-center items-center"><LoadingIcon /></div>}>
        <Hero />
        <Skills />
        <Resume />
        <Experience />
        <Contact />
        <Footer />
      </Suspense>
    </div>
  );
}

export default App;
