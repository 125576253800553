import React, { useState } from "react";
import { Link } from "react-scroll";
import { AiOutlineMenu } from "react-icons/ai";

const NavBar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="fixed top-0 z-50 w-full bg-[#ffffff] py-8 px-4 text-center">
      <div className="container mx-auto px-4 text-black">
        <div className="text-black flex items-center justify-between">
          <Link
            activeClass="active"
            to="hero"
            spy={true}
            smooth={true}
            offset={-70}
            duration={1000}
            className="text-2xl font-bold cursor-pointer hover:scale-105 duration-300"
          >
            ROBIN KIM
          </Link>
          <div className="uppercase hidden md:flex flex-grow items-end justify-end space-x-6 font-semibold">
            <Link
              to="hero"
              spy={true}
              smooth={true}
              offset={-70}
              duration={1000}
              className="p-2 cursor-pointer hover:underline-offset-8 hover:underline"
            >
              Home
            </Link>
            <Link
              to="skills"
              spy={true}
              smooth={true}
              offset={-70}
              duration={1000}
              className="p-2 cursor-pointer hover:underline-offset-8 hover:underline"
            >
              Skills
            </Link>
            <Link
              to="resume"
              spy={true}
              smooth={true}
              offset={-70}
              duration={1000}
              className="p-2 cursor-pointer hover:underline-offset-8 hover:underline"
            >
              Resume
            </Link>
            <Link
              to="experience"
              spy={true}
              smooth={true}
              offset={-70}
              duration={1000}
              className="p-2 cursor-pointer hover:underline-offset-8 hover:underline"
            >
              Projects
            </Link>
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={1000}
              className="p-2 cursor-pointer hover:underline-offset-8 hover:underline"
            >
              Contact
            </Link>
          </div>
          <div className="md:hidden flex justify-end items-center">
            <AiOutlineMenu
              onClick={toggleMobileMenu}
              className="text-xl hover:text-[#3c6e71]"
            />
          </div>
        </div>
      </div>

      {/* Mobile response */}
      {isMobileMenuOpen && (
        <div className="text-black md:hidden absolute z-10 top-0 right-0 h-screen w-[33.33%] bg-white shadow-xl transition-transform transform translate-x-0">
          <div className="flex items-center justify-end p-4">
            <AiOutlineMenu onClick={toggleMobileMenu} size={20} />
          </div>
          <div className="uppercase text-lg flex flex-col items-center mt-10 font-semibold space-y-4">
            <Link
              to="hero"
              spy={true}
              smooth={true}
              offset={-70}
              duration={1000}
              className="hover:text-[#3c6e71] cursor-pointer hover:underline-offset-8 hover:underline"
            >
              Home
            </Link>
            <Link
              to="skills"
              spy={true}
              smooth={true}
              offset={-70}
              duration={1000}
              className="hover:text-[#3c6e71] cursor-pointer hover:underline-offset-8 hover:underline"
            >
              Skills
            </Link>
            <Link
              to="resume"
              spy={true}
              smooth={true}
              offset={-70}
              duration={1000}
              className="hover:text-[#3c6e71] cursor-pointer hover:underline-offset-8 hover:underline"
            >
              Resume
            </Link>
            <Link
              to="experience"
              spy={true}
              smooth={true}
              offset={-70}
              duration={1000}
              className="hover:text-[#3c6e71] cursor-pointer hover:underline-offset-8 hover:underline"
            >
              Projects
            </Link>
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={1000}
              className="hover:text-[#3c6e71] cursor-pointer hover:underline-offset-8 hover:underline"
            >
              Contact
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavBar;
